import React from 'react'
import { graphql } from 'gatsby'
import { Col, Container, Row } from 'react-grid-system'
import Layout from '../../components/StructureComponents/Layout'
import Header from '../../components/shared/Header'
import FooterSection from '../../components/PageComponents/FooterSection'
import ThemeChanger from '../../components/shared/ThemeChanger'
import HeroSectionTwo from '../../components/PageComponents/HeroSectionTwo'
import SubscribeSection from '../../components/PageComponents/SubscribeSection'
import { Helmet } from 'react-helmet'
import ReleaseItem from '../../components/PageComponents/ReleaseItem'
import { nanoid } from 'nanoid'
import PrimaryHeading from '../../components/shared/PrimaryHeading'
import SecondaryHeading from '../../components/shared/SecondaryHeading/index'
import ReleaseItemAccordion from '../../components/PageComponents/ReleaseItemAccordion'

// Export Template for use in CMS preview
// Export Default WhatsNewIndex for front-end
const WhatsNewIndex = ({ data: { page, indexPage, allReleases }, props }) => {
  // debugger
  const { title, description } = page?.frontmatter
  const releases = allReleases.edges

  const groupReleasesByDate = (items = []) => {
    let releaseObj = {}

    items.forEach(({ node }) => {
      const curYear = new Date(node.frontmatter.date).getFullYear()
      if (releaseObj[curYear]) {
        console.log('this is already added')
        releaseObj[curYear].push(node)
      } else {
        releaseObj[curYear] = [node]
      }
    })

    return releaseObj
  }

  const groupedReleaseItems = groupReleasesByDate([...releases])

  const metaToShow = {
    ...page.frontmatter.meta,
    canonicalLink: '/whats-new'
  }

  return (
    <Layout meta={metaToShow} title={page?.frontmatter?.title || false}>
      <Helmet>
        <meta name={`robots`} content={`noindex, nofollow`} />
        <meta name={`googlebot`} content={`noindex, nofollow`} />
      </Helmet>

      <Header />
      <ThemeChanger />

      <main className="whatsNewPage">
        <section className="whatsNewPage__releases">
          <div className="whatsNewPage__hero">
            <Container>
              <h1 className="whatsNewPage__heading">What's New </h1>
            </Container>
          </div>

          <Container>
            {Object.keys(groupedReleaseItems)
              .reverse()
              .map((key, i) => (
                <ReleaseItemAccordion
                  key={i}
                  title="For Year"
                  subTitle={key}
                  releaseItems={[...groupedReleaseItems[key]]}
                />
              ))}

            {/* <Row>
              {[...releases]?.splice(1)?.map(({ node }) => (
                <Col key={nanoid()} lg={4} md={6} sm={12}>
                  <ReleaseItem {...node.frontmatter} />
                </Col>
              ))}
            </Row> */}
          </Container>
        </section>
      </main>

      <SubscribeSection {...indexPage.frontmatter.subscribeSection} />
      <FooterSection {...indexPage.frontmatter.footersection} />
    </Layout>
  )
}

{
  /* <h1 className="whatsNewPage__year">
                    For Year <span> {key}</span>{' '}
                  </h1>
                  {[...groupedReleaseItems[key]]?.map((node) => (
                    <Col key={nanoid()} lg={4} md={6} sm={12}>
                      <ReleaseItem {...node.frontmatter} />
                    </Col>
                  ))} */
}

export default WhatsNewIndex

export const pageQuery = graphql`
  query WhatsNewIndex {
    indexPage: markdownRemark(frontmatter: { template: { eq: "HomePage" } }) {
      frontmatter {
        footersection {
          primaryHeading
          description
          secondaryheading
          tertiaryheading
          footerList {
            primaryheading
            footerLink
          }
        }
        subscribeSection {
          title
          description
          secondaryButton {
            buttonText
            buttonLink
          }
        }
      }
    }

    allReleases: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "whats_new_releases" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            slug
            date(formatString: "MMM D YYYY")
            status
            short_desc
            featuredimage {
              image
              alt
            }
          }
        }
      }
    }

    page: markdownRemark(frontmatter: { template: { eq: "WhatsNewIndex" } }) {
      frontmatter {
        title
        description
      }
    }
  }
`
